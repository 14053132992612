import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import {
  MdAdd,
  MdAnimation,
  MdRoom,
  MdExpandLess,
  MdExpandMore,
} from 'react-icons/md';
import axios from 'axios';

import './../../mainStyle.scss';
import { CgDanger } from 'react-icons/cg';
import { useState, useRef } from 'react';

import configData from '../../config/config';
import { axiosError } from '../../utilities';

function EnvironmentNode(props) {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  async function deleteEnvironment(id) {
    try {
      await axios.delete(configData.SERVER_URL + '/environment/' + id, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem('token')).token
          }`,
        },
      });

      navigate(0);
    } catch (error) {
      axiosError(navigate, error);
    }
  }

  let childrenNodes = props.node.children
    .sort((a, b) =>
      a.description > b.description
        ? 1
        : a.description < b.description
          ? -1
          : 0,
    )
    .map((child) => (
      <EnvironmentNode key={child.id} node={child} parent={props.node.id} />
    ));

  const handleClose = () => setShowModal(false);
  const handleOpen = (id) => {
    setShowModal(true);
  };

  function showContent() {
    setIsOpen(true);
  }
  function hideContent() {
    setIsOpen(false);
  }

  const ref = useRef();

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      ref.current.focus();
    }
  };

  return (
    <>
      <Modal show={showModal} onHide={handleClose} onKeyDown={handleKeyDown}>
        <Modal.Header closeButton>
          <Modal.Title>Enviornment management</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Please select an action to perform</p>
        </Modal.Body>

        <Modal.Footer>
          <Link
            to={'/dashboard/environments/' + props.node.id}
            className="envLink">
            <Button variant="primary" size="lg" className="envNode">
              Open '{props.node.description}'
            </Button>
          </Link>

          {/* ADD */}
          {!props.node.isObject && (
            <Link to={'/dashboard/environments/create/' + props.node.id}>
              <Button variant="success" className="envNodeAdd" ref={ref}>
                <MdAdd />
                Add sub Env
              </Button>
            </Link>
          )}

          {/* DELETE */}
          <Button
            variant="danger"
            onClick={() => deleteEnvironment(props.node.id)}
            className="envNodeDelete">
            <CgDanger />
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {isOpen && props.node.children.length > 0 && (
        <Button
          variant="success"
          onClick={() => hideContent()}
          className="envBtnExpand">
          <MdExpandLess />
        </Button>
      )}
      {!isOpen && props.node.children.length > 0 && (
        <Button
          variant="success"
          onClick={() => showContent()}
          className="envBtnExpand">
          <MdExpandMore />
        </Button>
      )}

      {props.node.isCollecting && (
        <Button
          variant="primary"
          size="lg"
          className="envNodeIsCollecting"
          onClick={handleOpen}>
          {props.node.isObject && !props.node.isFixed && <MdAnimation />}
          {props.node.isObject && props.node.isFixed && <MdRoom />}

          {props.node.description}
        </Button>
      )}
      {!props.node.isCollecting && (
        <Button
          variant="primary"
          size="lg"
          className="envNodeIsNotCollecting"
          onClick={handleOpen}>
          {props.node.isObject && !props.node.isFixed && <MdAnimation />}
          {props.node.isObject && props.node.isFixed && <MdRoom />}

          {props.node.description}
        </Button>
      )}

      <br />

      {isOpen && props.node.children && (
        <>
          <ul className="list">{childrenNodes}</ul>
          <div className="hr" />
        </>
      )}
    </>
  );
}

export default EnvironmentNode;
