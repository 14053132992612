import styles from './TopBar.module.css';

function TopBar(props) {
  return (
    <>
      <div className={styles.topbar}>
        <p><span className={styles.dot} /> {props.text}</p>
      </div>
    </>
  );
}

export default TopBar;
