import { useState } from 'react';
import { Container, Card, Button, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import { useParams } from 'react-router-dom';

import HistoryGraph from '../components/history/HistoryGraph';

import styles from './ChartsDetails.module.css';

function ChartsDetails(props) {
  const params = useParams();

  const idPatient = params.id;
  const [realtime, setRealTime] = useState(true);

  var now = new Date();
  now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
  var nowSub = new Date();
  nowSub.setMinutes(now.getMinutes() - now.getTimezoneOffset() - 5);

  const [dateTimeStart, setDateTimeStart] = useState(
    nowSub.toISOString().slice(0, 16),
  );
  const [dateTimeEnd, setDateTimeEnd] = useState(
    now.toISOString().slice(0, 16),
  );
  const [interval, setInterval] = useState({ value: 60, label: '1 min' });

  function restoreRealTime() {
    setRealTime(true);
  }

  function showData() {
    setRealTime(false);
  }

  const options = [
    { value: 10000, label: '10 sec' },
    { value: 30000, label: '30 sec' },
    { value: 60000, label: '1 min' },
    { value: 300000, label: '5 min' },
    { value: 900000, label: '15 min' },
    { value: 1800000, label: '30 min' },
    { value: 3600000, label: '1 h' },
    { value: 43200000, label: '12 h' },
    { value: 86400000, label: '1 d' },
  ];

  return (
    <>
      <Container>
        <Card>
          <Card.Header>Setting</Card.Header>
          <Card.Body>
            <p>Start Date</p>
            <input
              aria-label="Date and time"
              type="datetime-local"
              value={dateTimeStart}
              onChange={(e) => setDateTimeStart(e.target.value)}
            />
            <p>End date</p>
            <input
              aria-label="Date and time"
              type="datetime-local"
              value={dateTimeEnd}
              onChange={(e) => setDateTimeEnd(e.target.value)}
            />
            <p>Interval</p>
            <Select
              options={options}
              value={interval}
              onChange={(e) =>
                setInterval({
                  value: e.value,
                  label: e.label,
                })
              }
            />

            <br />

            <Button onClick={() => showData()}>Show data</Button>

            <hr />

            <Button onClick={() => restoreRealTime()}>Restore real time</Button>
          </Card.Body>
        </Card>
      </Container>

      <br />

      <Container>
        <Row>
          <Col>
            <div className={styles.chart}>
              <HistoryGraph
                idPatient={idPatient}
                isBeacon="true"
                field="temperature"
                realtime={realtime}
                name="Temperature"
                dateTimeStart={dateTimeStart}
                dateTimeEnd={dateTimeEnd}
                interval={interval.value}
              />
            </div>
          </Col>
          <Col>
            <div className={styles.chart}>
              <HistoryGraph
                idPatient={idPatient}
                isBeacon="true"
                field="humidity"
                realtime={realtime}
                name="Humidity"
                dateTimeStart={dateTimeStart}
                dateTimeEnd={dateTimeEnd}
                interval={interval.value}
              />
            </div>
          </Col>
          <Col></Col>
        </Row>
        <Row>
          <Col>
            <div className={styles.chart}>
              <HistoryGraph
                idPatient={idPatient}
                isBeacon="true"
                field="accX"
                realtime={realtime}
                name="AccX"
                dateTimeStart={dateTimeStart}
                dateTimeEnd={dateTimeEnd}
                interval={interval.value}
              />
            </div>
          </Col>

          <Col>
            <div className={styles.chart}>
              <HistoryGraph
                idPatient={idPatient}
                isBeacon="true"
                field="accY"
                realtime={realtime}
                name="AccY"
                dateTimeStart={dateTimeStart}
                dateTimeEnd={dateTimeEnd}
                interval={interval.value}
              />
            </div>
          </Col>

          <Col>
            <div className={styles.chart}>
              <HistoryGraph
                idPatient={idPatient}
                isBeacon="true"
                field="accZ"
                realtime={realtime}
                name="AccZ"
                dateTimeStart={dateTimeStart}
                dateTimeEnd={dateTimeEnd}
                interval={interval.value}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className={styles.chart}>
              <HistoryGraph
                idPatient={idPatient}
                isBeacon="true"
                field="rssi"
                realtime={realtime}
                name="Rssi"
                dateTimeStart={dateTimeStart}
                dateTimeEnd={dateTimeEnd}
                interval={interval.value}
              />
            </div>
          </Col>
          <Col></Col>
          <Col></Col>
        </Row>
      </Container>

      <hr />

      <Container>
        <Row>
          <Col>
            <div className={styles.chart}>
              <HistoryGraph
                idPatient={idPatient}
                isBeacon="false"
                field="heartRate"
                realtime={realtime}
                name="HeartRate"
                dateTimeStart={dateTimeStart}
                dateTimeEnd={dateTimeEnd}
                interval={interval.value}
              />
            </div>
          </Col>
          <Col></Col>
          <Col></Col>
        </Row>
        <Row>
          <Col>
            <div className={styles.chart}>
              <HistoryGraph
                idPatient={idPatient}
                isBeacon="false"
                field="accX"
                realtime={realtime}
                name="AccX"
                dateTimeStart={dateTimeStart}
                dateTimeEnd={dateTimeEnd}
                interval={interval.value}
              />
            </div>
          </Col>

          <Col>
            <div className={styles.chart}>
              <HistoryGraph
                idPatient={idPatient}
                isBeacon="false"
                field="accY"
                realtime={realtime}
                name="AccY"
                dateTimeStart={dateTimeStart}
                dateTimeEnd={dateTimeEnd}
                interval={interval.value}
              />
            </div>
          </Col>

          <Col>
            <div className={styles.chart}>
              <HistoryGraph
                idPatient={idPatient}
                isBeacon="false"
                field="accZ"
                realtime={realtime}
                name="AccZ"
                dateTimeStart={dateTimeStart}
                dateTimeEnd={dateTimeEnd}
                interval={interval.value}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className={styles.chart}>
              <HistoryGraph
                idPatient={idPatient}
                isBeacon="false"
                field="gyroX"
                realtime={realtime}
                name="GyroX"
                dateTimeStart={dateTimeStart}
                dateTimeEnd={dateTimeEnd}
                interval={interval.value}
              />
            </div>
          </Col>

          <Col>
            <div className={styles.chart}>
              <HistoryGraph
                idPatient={idPatient}
                isBeacon="false"
                field="gyroY"
                realtime={realtime}
                name="GyroY"
                dateTimeStart={dateTimeStart}
                dateTimeEnd={dateTimeEnd}
                interval={interval.value}
              />
            </div>
          </Col>

          <Col>
            <div className={styles.chart}>
              <HistoryGraph
                idPatient={idPatient}
                isBeacon="false"
                field="gyroZ"
                realtime={realtime}
                name="GyroZ"
                dateTimeStart={dateTimeStart}
                dateTimeEnd={dateTimeEnd}
                interval={interval.value}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className={styles.chart}>
              <HistoryGraph
                idPatient={idPatient}
                isBeacon="false"
                field="magnX"
                realtime={realtime}
                name="MagnX"
                dateTimeStart={dateTimeStart}
                dateTimeEnd={dateTimeEnd}
                interval={interval.value}
              />
            </div>
          </Col>

          <Col>
            <div className={styles.chart}>
              <HistoryGraph
                idPatient={idPatient}
                isBeacon="false"
                field="magnY"
                realtime={realtime}
                name="MagnY"
                dateTimeStart={dateTimeStart}
                dateTimeEnd={dateTimeEnd}
                interval={interval.value}
              />
            </div>
          </Col>

          <Col>
            <div className={styles.chart}>
              <HistoryGraph
                idPatient={idPatient}
                isBeacon="false"
                field="magnZ"
                realtime={realtime}
                name="MagnZ"
                dateTimeStart={dateTimeStart}
                dateTimeEnd={dateTimeEnd}
                interval={interval.value}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className={styles.chart}>
              <HistoryGraph
                idPatient={idPatient}
                isBeacon="false"
                field="pressure"
                realtime={realtime}
                name="Pressure"
                dateTimeStart={dateTimeStart}
                dateTimeEnd={dateTimeEnd}
                interval={interval.value}
              />
            </div>
          </Col>
          <Col></Col>
          <Col></Col>
        </Row>
        <Row>
          <Col>
            <div className={styles.chart}>
              <HistoryGraph
                idPatient={idPatient}
                isBeacon="false"
                field="rssi"
                realtime={realtime}
                name="Rssi"
                dateTimeStart={dateTimeStart}
                dateTimeEnd={dateTimeEnd}
                interval={interval.value}
              />
            </div>
          </Col>
          <Col></Col>
          <Col></Col>
        </Row>
      </Container>
    </>
  );
}

export default ChartsDetails;
