import { Outlet, useNavigate } from 'react-router-dom';
import { Container, Card, Button, Form, Modal } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { Oval } from 'react-loader-spinner';

import styles from './Export.module.css';

import configData from '../config/config';
import constant from '../config/constant';

import { axiosError, ShowAlert } from '../utilities';

function Export() {
  var now = new Date();
  now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
  var nowSub = new Date();
  nowSub.setMinutes(now.getMinutes() - now.getTimezoneOffset() - 5);

  const [dateTimeStart, setDateTimeStart] = useState(
    nowSub.toISOString().slice(0, 16),
  );
  const [dateTimeEnd, setDateTimeEnd] = useState(
    now.toISOString().slice(0, 16),
  );

  const [patientAll, setPatientAll] = useState([]);

  const [patient, setPatient] = useState({ value: 0, label: 'All' });

  const [raspberryExport, setRaspberryExport] = useState(true);
  const [wearosExport, setWearosExport] = useState(true);

  const [deleteExport, setDeleteExport] = useState(false);

  const [showError, setShowError] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [showQuestion, setShowQuestion] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (showLoading === true) {
        setShowLoading(false);
        ShowAlert('Export timeout');
      }
    }, constant.timeOutExportMs);

    return () => clearTimeout(timeout);
  }, [showLoading]);

  useEffect(() => {
    async function getPatient() {
      try {
        const response = await axios.get(
          configData.SERVER_URL + '/user/patient',
          {
            headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.getItem('token')).token
              }`,
            },
          },
        );

        const data = await response.data;

        let patientForSelect = [{ value: 0, label: 'All' }];
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          patientForSelect.push({ value: element.id, label: element.username });
        }
        setPatientAll(patientForSelect);
      } catch (error) {
        axiosError(navigate, error);
      }
    }

    getPatient();
  }, [navigate]);

  async function getData(confirm) {
    if (confirm === false && deleteExport) {
      setShowQuestion(true);
      return;
    }

    setShowLoading(true);

    let firstPart = '';
    if (raspberryExport === true && wearosExport === true) {
      firstPart = '';
    } else if (raspberryExport === true && wearosExport === false) {
      firstPart = 'raspberry/';
    } else if (raspberryExport === false && wearosExport === true) {
      firstPart = 'wearos/';
    } else {
      return;
    }

    let tsStart = new Date(dateTimeStart).getTime();
    let tsEnd = new Date(dateTimeEnd).getTime();

    let url;
    if (patient.value !== 0) {
      url =
        configData.SERVER_URL +
        '/export/' +
        firstPart +
        tsStart +
        '/' +
        tsEnd +
        '/' +
        patient.value;
    } else {
      url =
        configData.SERVER_URL + '/export/' + firstPart + tsStart + '/' + tsEnd;
    }

    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem('token')).token
          }`,
        },
      });

      const data = await response.data;
      exportToCsv('export.csv', data);

      if (deleteExport) {
        await axios.delete(url, {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem('token')).token
            }`,
          },
        });
      }
    } catch (error) {
      axiosError(navigate, error);
    } finally {
      setShowLoading(false);
    }
  }

  function exportToCsv(filename, rows) {
    var csvFile = '';
    for (var i = 0; i < rows.length; i++) {
      csvFile += rows[i] + '\n';
    }

    var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      var link = document.createElement('a');
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  const handleClose = (e) => setShowError(false);

  return (
    <>
      <Outlet />

      <Modal show={showError} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Export error</Modal.Title>
        </Modal.Header>
        <Modal.Body>Error during export.</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Container>
        <Card>
          <Card.Header>Export</Card.Header>
          <Card.Body>
            <p>Start Date</p>
            <input
              aria-label="Date and time"
              type="datetime-local"
              value={dateTimeStart}
              onChange={(e) => setDateTimeStart(e.target.value)}
            />
            <p>End date</p>
            <input
              aria-label="Date and time"
              type="datetime-local"
              value={dateTimeEnd}
              onChange={(e) => setDateTimeEnd(e.target.value)}
            />

            <hr />

            <label htmlFor="patient">Patient</label>
            <Select
              id="patient"
              options={patientAll}
              value={patient}
              onChange={(e) =>
                setPatient({
                  value: e.value,
                  label: e.label,
                })
              }
            />

            <label>Filters</label>

            <Form.Check
              type="checkbox"
              id="raspberryExport"
              name="raspberryExport"
              value="true"
              checked={raspberryExport}
              onChange={(e) => setRaspberryExport(e.target.checked)}
              label="Raspberry"
            />

            <Form.Check
              type="checkbox"
              id="wearosExport"
              name="wearosExport"
              value="true"
              checked={wearosExport}
              onChange={(e) => setWearosExport(e.target.checked)}
              label="Wearos"
            />

            <hr />

            <Form.Check
              type="checkbox"
              id="deleteExport"
              name="deleteExport"
              value="true"
              checked={deleteExport}
              onChange={(e) => setDeleteExport(e.target.checked)}
              label="Delete data"
            />

            <hr />

            <Button
              onClick={() => getData(false)}
              disabled={!raspberryExport && !wearosExport}>
              Submit
            </Button>
          </Card.Body>
        </Card>
      </Container>

      {showLoading && <div className={styles.backdrop}></div>}
      {showLoading && (
        <div className={styles.spinnerClass}>
          <Oval
            visible="true"
            height="80"
            width="80"
            color="#4fa94d"
            ariaLabel="oval-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      )}

      <Modal show={showQuestion} onHide={() => setShowQuestion(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete data</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want also delete the data?</Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => getData(true)}>
            Ok
          </Button>
          <Button variant="primary" onClick={() => setShowQuestion(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Export;
