import { Outlet, Link } from 'react-router-dom';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { MdLogout, MdOutlineDeviceHub } from 'react-icons/md';
import { FaUsersGear } from 'react-icons/fa6';
import { AiOutlineLineChart } from 'react-icons/ai';
import styles from './NavBarSite.module.scss';
import { TiBusinessCard, TiExport } from 'react-icons/ti';

function NavBarSite() {
  return (
    <>
      <Navbar expand="lg" className={`bg-body-tertiary ${styles.navbg}`}>
        <Container className="navgbg">
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            {/*
            wait for a svg clean logo...
            <img
              className={styles.logoHeader}
              src={require('./../../assets/logo.jpg')}
              alt=""
              height="10%"
              width="10%"
            /> */}
            <Nav className="me-auto">
              <Nav.Link as={Link} to="users" className={`${styles.navitemfg}`}>
                <FaUsersGear /> Users
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="environments"
                className={`${styles.navitemfg}`}>
                <MdOutlineDeviceHub /> Environment
              </Nav.Link>
              <Nav.Link as={Link} to="charts" className={`${styles.navitemfg}`}>
                <AiOutlineLineChart /> Charts
              </Nav.Link>
              <Nav.Link as={Link} to="export" className={`${styles.navitemfg}`}>
                <TiExport /> Export Data
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="credits"
                className={`${styles.navitemfg}`}>
                <TiBusinessCard /> Credits
              </Nav.Link>
              <Nav.Link as={Link} to="logout" className={`${styles.navitemfg}`}>
                <MdLogout /> Logout
              </Nav.Link>
            </Nav>

            <Navbar.Text style={{ color: 'white' }}>
              Hello,{' '}
              {JSON.parse(localStorage.getItem('token')) &&
                JSON.parse(localStorage.getItem('token')).username}
            </Navbar.Text>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <br />
      <Outlet />
    </>
  );
}

export default NavBarSite;
