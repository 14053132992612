import { useNavigate } from 'react-router-dom';
import { Table, Button } from 'react-bootstrap';
import { MdAdd, MdRemove } from 'react-icons/md';
import axios from 'axios';

import configData from '../../config/config';
import { useEffect, useState } from 'react';
import { axiosError } from '../../utilities';

function DeviceBleList(props) {
  const navigate = useNavigate();
  const [environment, setEnvironments] = useState([]);

  useEffect(() => {
    async function prepareEnvironment() {
      try {
        const response = await axios.get(
          configData.SERVER_URL + '/environment/all',
          {
            headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.getItem('token')).token
              }`,
            },
          },
        );

        const data = await response.data;
        setEnvironments(data);
      } catch (error) {
        axiosError(navigate, error);
      }
    }

    prepareEnvironment();
  }, [navigate]);

  async function addBleDeviceToEnvironment(idDevice, idEnvironment) {
    try {
      await axios
        .post(
          configData.SERVER_URL +
            '/device/ble/' +
            idDevice +
            '/' +
            idEnvironment,
          {},
          {
            headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.getItem('token')).token
              }`,
            },
          },
        )
        .then(() => {
          navigate(0);
        });
    } catch (error) {
      axiosError(navigate, error);
    }
  }

  async function removeBleDeviceFromEnvironment(mac) {
    try {
      await axios
        .delete(configData.SERVER_URL + '/device/ble/mac/' + mac, {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem('token')).token
            }`,
          },
        })
        .then(() => {
          navigate(0);
        });
    } catch (error) {
      axiosError(navigate, error);
    }
  }

  function takeDescription(id) {
    for (let index = 0; index < environment.length; index++) {
      const element = environment[index];
      if (Number(element.id) === Number(id)) {
        return element.description;
      }
    }

    return '';
  }

  return (
    <>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Mac</th>
            <th>Name</th>
            <th>Manufacturer</th>
            <th>Environment</th>
            {props.showAdd && (
              <>
                <th>Actions</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {props.newDevice &&
            props.newDevice
              .sort((a, b) => (a.mac > b.mac ? 1 : a.mac < b.mac ? -1 : 0))
              .map((u) => (
                <tr key={u.mac}>
                  <td>{u.mac}</td>
                  <td>{u.name}</td>
                  <td>{u.manufacturer}</td>
                  {u.environment !== null &&
                    u.environment !== props.idEnvironment && (
                      <td>{environment && takeDescription(u.environment)}</td>
                    )}
                  {u.environment === null && props.showAdd && <td></td>}

                  {u.environment !== null &&
                    u.environment === props.idEnvironment && (
                      <td>{environment && takeDescription(u.environment)}</td>
                    )}

                  {props.showAdd && u.environment === null && (
                    <td>
                      <Button
                        variant="success"
                        onClick={() =>
                          addBleDeviceToEnvironment(u.mac, props.idEnvironment)
                        }
                        disabled={props.disabled}>
                        <MdAdd /> Add
                      </Button>
                    </td>
                  )}
                  {props.showAdd &&
                    u.environment !== props.idEnvironment &&
                    u.environment !== null && (
                      <td>
                        <Button
                          variant="success"
                          onClick={() =>
                            addBleDeviceToEnvironment(
                              u.mac,
                              props.idEnvironment,
                            )
                          }
                          disabled>
                          <MdAdd /> Add
                        </Button>
                      </td>
                    )}
                  {props.showAdd && u.environment === props.idEnvironment && (
                    <td>
                      <Button
                        variant="danger"
                        onClick={() => removeBleDeviceFromEnvironment(u.mac)}
                        disabled={props.disabled}>
                        <MdRemove /> Remove
                      </Button>
                    </td>
                  )}
                </tr>
              ))}
        </tbody>
      </Table>
    </>
  );
}

export default DeviceBleList;
