import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { MdDelete, MdShare, MdEdit } from 'react-icons/md';
import { Modal } from 'react-bootstrap';
import { Card } from 'react-bootstrap';
import { MdOutlineExpandMore, MdOutlineExpandLess } from 'react-icons/md';

import axios from 'axios';

import configData from '../../config/config';

import './../../mainStyle.scss';
import { useState } from 'react';
import { axiosError } from '../../utilities';

function UserList(props) {
  const navigate = useNavigate();

  const [user, setUsers] = useState([]);

  const [showDeleteAlert, setShowDeleteAlert] = useState();
  const [toDelete, setToDelete] = useState();
  const [actualShare, setActualShare] = useState(null);
  const [expanded, setExpanded] = useState();

  useEffect(() => {
    async function getActiveShare() {
      try {
        const response = await axios.get(
          configData.SERVER_URL + '/user/share',
          {
            headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.getItem('token')).token
              }`,
            },
          },
        );

        const data = await response.data;
        setActualShare(data);
      } catch (error) {
        axiosError(navigate, error);
      }
    }

    async function getUser() {
      try {
        const response = await axios.get(configData.SERVER_URL + '/user', {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem('token')).token
            }`,
          },
        });

        const data = await response.data;
        setUsers(data);
      } catch (error) {
        axiosError(navigate, error);
      }
    }

    getActiveShare();
    getUser();
  }, [navigate]);

  async function deleteUser() {
    let id = toDelete;
    try {
      await axios
        .delete(configData.SERVER_URL + '/user/' + id, {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem('token')).token
            }`,
          },
        })
        .then(() => {
          navigate(0);
        });
    } catch (error) {
      axiosError(navigate, error);
    }
  }

  async function addShare(otherId) {
    try {
      await axios
        .post(
          configData.SERVER_URL + '/user/share/' + otherId,
          {},
          {
            headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.getItem('token')).token
              }`,
            },
          },
        )
        .then(() => {
          navigate(0);
        });
    } catch (error) {
      axiosError(navigate, error);
    }
  }

  async function deleteShare(otherId) {
    try {
      await axios
        .delete(configData.SERVER_URL + '/user/share/' + otherId, {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem('token')).token
            }`,
          },
        })
        .then(() => {
          navigate(0);
        });
    } catch (error) {
      axiosError(navigate, error);
    }
  }

  function isInList(id) {
    for (let index = 0; index < actualShare.length; index++) {
      const element = actualShare[index];
      if (Number(element.id) === Number(id)) {
        return true;
      }
    }

    return false;
  }

  const handleClose = () => setShowDeleteAlert(false);
  const handleOpen = (id) => {
    setShowDeleteAlert(true);
    setToDelete(id);
  };

  return (
    <>
      <Modal show={showDeleteAlert} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>User delete</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            Are you sure you want to delete this user? <br /> This action is
            irreversible.
          </p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={deleteUser}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {user &&
        user
          .sort((a, b) =>
            a.username > b.username ? 1 : a.username < b.username ? -1 : 0,
          )
          .filter((element) => {
            if (props.other && element.isAdmin === false) {
              return false;
            }

            if (
              !props.other &&
              JSON.parse(localStorage.getItem('token')).id === element.id
            ) {
              return true;
            } else if (
              props.other &&
              JSON.parse(localStorage.getItem('token')).id === element.id
            ) {
              return false;
            }

            if (
              element.owner === JSON.parse(localStorage.getItem('token')).id &&
              !props.other
            ) {
              return true;
            } else if (
              element.owner !== JSON.parse(localStorage.getItem('token')).id &&
              props.other
            ) {
              return true;
            }

            return false;
          })
          .map((u) => (
            <Card className="userNeutralCard" key={u.id}>
              <Card.Body>
                <Card.Title>
                  {expanded === u.id ? (
                    <>
                      <MdOutlineExpandMore onClick={() => setExpanded(null)} />
                    </>
                  ) : (
                    <>
                      <MdOutlineExpandLess onClick={() => setExpanded(u.id)} />
                    </>
                  )}
                  &nbsp;
                  {u.username}
                </Card.Title>
                {expanded === u.id ? (
                  <>
                    <p className="noSpacing">- Username: {u.username}</p>
                    <p className="noSpacing">- Name: {u.username}</p>
                    <p className="noSpacing">- Surname: {u.surname}</p>
                    <p className="noSpacing">- Email: {u.email}</p>
                    <hr />
                    <div className="flex-row">
                      {u.id ===
                        JSON.parse(localStorage.getItem('token')).id && (
                        <Link to={'edit/' + u.id}>
                          <Button variant="success" className="btn-primary">
                            <MdEdit />
                            Edit
                          </Button>
                        </Link>
                      )}
                      {u.owner ===
                        JSON.parse(localStorage.getItem('token')).id &&
                        u.id !==
                          JSON.parse(localStorage.getItem('token')).id && (
                          <Link to={'edit/' + u.id}>
                            <Button variant="success" className="btn-primary">
                              <MdEdit />
                              Edit
                            </Button>
                          </Link>
                        )}

                      {u.owner ===
                        JSON.parse(localStorage.getItem('token')).id &&
                        u.id !==
                          JSON.parse(localStorage.getItem('token')).id && (
                          <Link to={u.id} onClick={() => handleOpen(u.id)}>
                            <Button variant="danger">
                              <MdDelete />
                              Delete
                            </Button>
                          </Link>
                        )}

                      {actualShare &&
                        isInList(u.id) &&
                        u.isAdmin &&
                        u.id !==
                          JSON.parse(localStorage.getItem('token')).id && (
                          <Button
                            variant="danger"
                            onClick={() => deleteShare(u.id)}
                            className="btn-danger">
                            <MdDelete />
                            Delete share
                          </Button>
                        )}
                      {actualShare &&
                        !isInList(u.id) &&
                        u.isAdmin &&
                        u.id !==
                          JSON.parse(localStorage.getItem('token')).id && (
                          <Button
                            variant="success"
                            onClick={() => addShare(u.id)}
                            className="btn-primary">
                            <MdShare />
                            Share with this user
                          </Button>
                        )}
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </Card.Body>
            </Card>
          ))}
    </>
  );
}

export default UserList;
