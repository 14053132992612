import EnvironmentNode from './EnvironmentNode';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { axiosError } from '../../utilities';
import { useNavigate } from 'react-router-dom';

import configData from '../../config/config';

function EnvironmentList() {
  const [environment, setEnvironment] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    async function getEnvironments() {
      try {
        const response = await axios.get(
          configData.SERVER_URL + '/environment',
          {
            headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.getItem('token')).token
              }`,
            },
          },
        );

        const data = await response.data;
        setEnvironment(data);
      } catch (error) {
        axiosError(navigate, error);
      }
    }

    getEnvironments();
  }, [navigate, setEnvironment]);

  return (
    <>
      {environment &&
        environment
          .sort((a, b) =>
            a.description > b.description
              ? 1
              : a.description < b.description
                ? -1
                : 0,
          )
          .map((node) => (
            <EnvironmentNode key={node.id} parent={node.id} node={node} />
          ))}
    </>
  );
}

export default EnvironmentList;
