let updateIntervalMs = 5 * 1000;
let timeOutGetScanBleMs = 120 * 1000;
let timeOutExportMs = 600 * 1000;
let redirectPageMs = 1000;

module.exports = {
  updateIntervalMs,
  timeOutGetScanBleMs,
  timeOutExportMs,
  redirectPageMs,
};
