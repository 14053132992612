import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import axios from 'axios';
import { Oval } from 'react-loader-spinner';

import styles from './HistoryGraph.module.css';

import configData from '../../config/config';
import constant from '../../config/constant';

function HistoryGraph(props) {
  const [series, setSeries] = useState([]);
  const [showLoading, setShowLoading] = useState(true);

  const options = {
    chart: {
      id: 'realtime-chart',
      type: 'line',
      height: 350,
      animations: {
        enabled: false,
        easing: 'linear',
        dynamicAnimation: {
          enabled: true,
          easing: 'linear',
          speed: 200,
        },
      },
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    xaxis: {
      type: 'datetime',
    },
    yaxis: {
      autoScaleYaxis: true,
    },
    dataLabels: {
      enabled: false,
      formatter: function (val) {
        return parseFloat(val).toFixed(1);
      },
    },
    stroke: {
      width: props.realtime ? 1 : 0,
    },
    markers: {
      size: props.realtime ? 0 : 3,
    },
    title: {
      text: props.name,
      align: 'center',
    },
  };

  useEffect(() => {
    const fetchData = async () => {
      const now = new Date();
      // 1 min
      const timestamp = new Date(now.getTime() - 1 * 60 * 1000).getTime();

      try {
        let url = '';
        if (props.realtime) {
          url = `${configData.SERVER_URL}/chart/${props.idPatient}/realtime/${timestamp}/${props.field}/${props.isBeacon}`;
        } else {
          const nowStartMilli = Date.parse(props.dateTimeStart);
          const nowEndMilli = Date.parse(props.dateTimeEnd);

          url = `${configData.SERVER_URL}/chart/${props.idPatient}/${nowStartMilli}/${nowEndMilli}/${props.field}/${props.isBeacon}/${props.interval}`;
        }

        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem('token')).token
            }`,
          },
        });
        const data = await response.data;

        let myDataset = [];
        let table = [];
        if (!props.isBeacon && props.field !== 'rssi') {
          myDataset = [
            {
              name: 'Wearos',
              data: [],
            },
          ];

          table.push(data.numberData[0].id);
        } else {
          for (let index = 0; index < data.numberData.length; index++) {
            myDataset.push({
              name: data.numberData[index].name,
              data: [],
            });

            table.push(data.numberData[index].id);
          }
        }

        data.dataVisualize.forEach((element) => {
          for (let index = 0; index < element.value.length; index++) {
            const singleValue = element.value[index];
            myDataset[
              table.findIndex((o) => o === singleValue.first)
            ].data.push({
              x: element.timeStamp,
              y: singleValue.second,
            });
          }
        });

        setSeries(myDataset);
        setShowLoading(false);

        // setSeries([{ name: 'Series 1', data: [1, 2, 3, 4] }]);
        // [{ name: 'Series 1', data: series }]
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (props.realtime) {
      const interval = setInterval(fetchData, constant.updateIntervalMs);
      return () => clearInterval(interval);
    } else {
      setShowLoading(true);
      fetchData();
    }
  }, [
    props.realtime,
    props.dateTimeStart,
    props.dateTimeEnd,
    props.idPatient,
    props.interval,
    props.field,
    props.isBeacon,
  ]);

  return (
    <>
      {series && (
        <Chart options={options} series={series} type="line" height={200} />
      )}

      {showLoading && <div className={styles.backdrop}></div>}
      {showLoading && (
        <div className={styles.spinnerClass}>
          <Oval
            visible="true"
            height="80"
            width="80"
            color="#4fa94d"
            ariaLabel="oval-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      )}
    </>
  );
}

export default HistoryGraph;
